<template>

    <section>
        <h3>This page does not exist</h3>
        <p>Go back to <a href="https://happysoup.io">HappySoup.io</a></p>
    </section>
 
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    section {
        margin: auto;
        width: 300px;
        margin-top: 121px;
    }

</style>