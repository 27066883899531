<template>
  <form @submit.prevent >

    <div class="section-title">
      CI/CD Details
    </div>
   
   
    <div class="field">
      <label class="label">CI/CD Provider</label>
      <p class="explanation">
        Select the CI provider your remote git repository
        is connected to
      </p>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="cicdProvider">
            <option value="github">GitHub Actions</option>
            <option disabled>Bitbucket (coming soon)</option>
            <option disabled>GitLab (coming soon)</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-building"></i>
        </div>
      </div>
    </div>

     <div class="field" v-if="cicdProvider != ''">
      <label class="label">Job name</label>
      <p class="explanation">
        Describe what in just a <b>few</b> words is you are automating, i.e "Deployment to UAT" or
        "Validate deployment when PR is created."
      </p>
      <div class="control has-icons-right">
        <input
          class="input"
          v-model="jobName"
          type="text"
          placeholder="i.e Deployment to UAT"
        />
      </div>
    
    </div>

    <div  v-if="jobName != ''">
    <div class="section-title">
      Authentication
    </div>

    <div class="field" >
      <label class="label">Org authentication method</label>
      <div class="explanation">
       <p>Your pipeline configuration script needs to authenticate to your target Salesforce org without any manual user interaction. You can use two methods:</p>
       <br>
       <p>1) You can use the 
        <b><a target="_blank" href="https://developer.salesforce.com/docs/atlas.en-us.sfdx_cli_reference.meta/sfdx_cli_reference/cli_reference_auth_sfdxurl.htm">sfdx url</a></b> 
       generated from visual studio code. This is very easy and <b>recommended</b></p>
       <br>
       <p>2) You can use the <b><a href="https://developer.salesforce.com/docs/atlas.en-us.sfdx_dev.meta/sfdx_dev/sfdx_dev_auth_jwt_flow.htm"
        target="_blank" >JSON web token flow (JWT)</a></b>. This requires you to create a connecte app in <b>each</b> target org 
       and create a certificate.
       </p>
       <br>
       <p>Regardless of which method you use, you will end up with a set of parameters that need to be passed to the Salesforce CLI for authentication. You must generate those parameters (see links above)
        and then come back to this step.
       </p>
      </div>
      <br>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="authentication">
            <option value="sfdxurl">Sfdx URL (recommended)</option>
            <option value="jwt">JWT</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </div>
      </div>
    </div>

    <section v-if="authentication != ''">
    <p>Now that you've generated the authentication parameters, you must stored them as environment variables or secrets in 
      your CI server. Once you've done that, you need to enter the name of those secrets in the fields below.
      </p>
      <br>
      <article class="message is-danger">
        <div class="message-body">
          Do <b>not</b> provide the actual values. Provide the <b>names</b>
          of the secrets that hold these values.
        </div>
    </article>
    </section>
    <br>

    <section v-if="authentication == 'jwt'">

      <div class="field">
      <label class="label">Salesforce consumer key CI secret</label>
      <div class="control has-icons-right">
        <input
          class="input uppercase"
          v-model="consumerKeySecret"
          type="text"
          placeholder="SALESFORCE_CONSUMER_KEY"
        />
      </div>
      
    </div>

    <div class="field">
      <label class="label">JWT secret key CI secret</label>
      <div class="control has-icons-right">
        <input
          class="input uppercase"
          v-model="jwtSecretKey"
          type="text"
          placeholder="SALESFORCE_JWT_SECRET_KEY"
        />
      </div>
     
    </div>

    <div class="field">
      <label class="label">Salesforce username CI secret</label>
      <div class="control has-icons-right">
        <input
          class="input uppercase"
          v-model="salesforceUsernameSecret"
          type="text"
          placeholder="SALESFORCE_USERNAME"
        />
      </div>
      
    </div>

    <div class="field">
      <label class="label">Salesforce login URL CI secret</label>
      <div class="control has-icons-right">
        <input
          class="input uppercase"
          v-model="salesforceLoginUrlSecret"
          type="text"
          placeholder="SALESFORCE_LOGIN_URL"
        />
      </div>
      
    </div>


    </section>
    </div>

    <section v-if="authentication == 'sfdxurl'">

      <div class="field">
      <label class="label">SFDX URL CI secret</label>
      <div class="control has-icons-right">
        <input
          class="input uppercase"
          v-model="sfdxUrlSecret"
          type="text"
          placeholder="SFDX_URL"
        />
      </div>
      
    </div>

    </section>

    <div v-if="authentication != ''">
    <div class="section-title" >
      Automation
    </div>

    <div class="field">
      <label class="label">Job type</label>
      <p class="explanation">
        Here you specify what it is you want to automate in your release process against 
        the target org
      </p>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="jobType">
            <option value="tests">Run tests only</option>
            <option value="validation">Validate deployment</option>
            <option value="deployment">Deployment</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-briefcase"></i>
        </div>
      </div>
    </div>

    <div class="field" v-if="['validation','deployment'].includes(jobType)">
      <label class="label">Deployment type</label>
      <p class="explanation">
        You can deploy the entire metadata in the sfdx project or only the 
        metadata that has been created/updated since the last commit (similar to using change sets).
      </p>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="deploymentType">
            <option value="all">Deploy entire sfdx project</option>
            <option value="delta">Deploy delta (changed files)</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-code"></i>
        </div>
      </div>
    </div>

     <div class="field" v-if="jobType != ''">
      <label class="label">Event</label>
      <p class="explanation">
        Now, decide when you want to <b>{{selectedJobFriendlyName}}</b>. 
        The trigger is an event that happens in your remote git repository.
      </p>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="trigger">
            <option value="pull_request">Pull request is opened/updated</option>
            <option value="push">On push</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-code-branch"></i>
        </div>
      </div>
    </div>


    <div class="field" v-if="showBranchesAndPaths">
      <label class="label">Target branches</label>
      <p class="explanation">
        Do you want this pipeline to run when a {{trigger}} happens on any branch? If not, write down the names
        of the specific branches that are relevant to this automation. You can enter multiple branches separted by a comma       
      </p>
      <div class="control has-icons-right">
        <input
          class="input"
          v-model="branches"
          type="text"
          placeholder="e.g main,master,feature* or leave blank if this applies to any branch"
        />
      </div>
    </div>

    <div class="field" v-if="showBranchesAndPaths">
      <label class="label">Run when changes are made to these paths</label>
      <p class="explanation">
        When using the push and pull request events, you can configure a workflow to run based on what file paths are changed.
        By default, we are tracking changes inside the <code>force-app/**</code> directory; the standard sfdx directory.
      </p>
      <div class="control has-icons-right">
        <input
          class="input"
          v-model="paths"
          type="text"
          placeholder="force-app/** (default)"
        />
      </div>
    </div>
    </div>

    <div v-if="jobType != 'tests' && jobType != '' && trigger != ''"> 
     <div class="section-title">
      Checks
    </div>

    <div class="field">
      <label class="label">Run apex tests</label>
      <p class="explanation">
        You can run all apex tests in the org or tests specified by the developer in the pull request body.
      </p>
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="testsType">
            <option selected value="all">Run all tests</option>
            <option selected value="specified">Developer can specify tests in the pull request</option>
          </select>
        </div>
        <div class="icon is-small is-left">
          <i class="fas fa-check"></i>
        </div>
      </div>
    </div>

    <article v-if="testsType == 'specified' " class="message is-warning">
        <div class="message-body">
        In order to let the developer specify which tests they want to run, they must use 
        the following syntax anywhere in the pull request comment:
        <br><br>
        <code>apex::[testClass1,testClass2]::apex</code>
        <br><br>
        If this text is not found in the comment, all tests will be run.
        <br>
        </div>
    </article>

    <h3><b>Static Code Analysis (PMD)</b></h3>
    <br>
    <div class="field">
      <div class="control">
        <label>
          <input v-model="runPMD" type="checkbox" />
          Enable
        </label>
      </div>
    </div>

    <section v-if="runPMD == true" class="pmd">

        <p>Choose which <a href="https://pmd.github.io/latest/pmd_rules_apex.html" target="_blank">PMD Categories</a> you wish to analyse.</p>
        <br>
        <div class="field">
        <div class="control">
            <label>
            <input v-model="pmdCategories" type="checkbox" value="Design" />
            Design
            </label>
        </div>
        </div>

        <div class="field">
        <div class="control">
            <label>
            <input v-model="pmdCategories" type="checkbox" value="Best Practices"/>
            Best Practices
            </label>
        </div>
        </div>

        <div class="field">
        <div class="control">
            <label>
            <input v-model="pmdCategories" type="checkbox" value="Performance" />
            Performance
            </label>
        </div>
        </div>

        <div class="field">
        <div class="control">
            <label>
            <input v-model="pmdCategories" type="checkbox" value="Code Style" />
            Code Style
            </label>
        </div>
        </div>

        <div class="field">
        <div class="control">
            <label>
            <input v-model="pmdCategories" type="checkbox" value="Security"/>
            Security
            </label>
        </div>
        </div>

    </section>
    </div>

    <div class="field">
      <div class="control">
        <button class="button cta"  @click="submit" :disabled="!isFormValid" >
          <span v-if="formSubmitted == false">Create pipeline</span>
          <span v-if="formSubmitted">Update pipeline</span>
        </button>
      </div>
    </div>
  </form>

  <br>
  <article class="message is-success" v-if="response != ''">
    <div class="message-body">
      Congrats! You just saved hours of research and typing!
    </div>
  </article>

  <section class="results">
    <article class="message is-danger" v-if="error != ''">
          <div class="message-body">
            {{error}}
          </div>
    </article>

    <section v-if="response != ''">

    

    <section class="instructions">
      <h1>Instructions</h1>
      <div v-if="cicdProvider == 'github'">
        <ol>
          <li>Create a <code>.github/workflows</code> directory in the root directory of your sfdx project</li>
          <li>Copy/paste the contents below into a new file in this new directory, with the name <code>{{fileName}}.yml</code> </li>
          <li v-if="testsType == 'specified'">
            Because you want to run apex tests based on what the developer specifies in the pull request, you must 
            save <b><a href="https://github.com/pgonzaleznetwork/dreamforce22-org/blob/main/parsePR.js" target="_blank" >this javascript file</a></b>  in the root directory of your sfdx project, under the name <code>parsePR.js</code>
          </li>
          <li v-if="testsType == 'specified'">
            It is recommended that you create a pull request template that includes the special apex identifier to encourage
            developers to specify which tests to run. Here's a <b><a href="https://github.com/pgonzaleznetwork/dreamforce22-org/blob/main/pull_request_template.md" target="_blank">sample</a></b>.
          </li>
          <li>Commit the changes and push them to the remote repository. Please note that the workflow will <b>not</b>
          run on a branch unless the branch was created after adding the file to the repository</li>
        </ol>
        <br>
        You can learn more about GitHub Actions <a href="https://docs.github.com/en/actions/quickstart" target="_blank">here</a>. 
        <br><br>
        <p>Happy CI/CD'ing!</p>
      </div>
  </section>

  <pre @click="copy(response)"><code>{{response}}</code></pre>

    </section>

  </section>

  

  
  

</template>

<script>

import jobSubmission from '@/functions/jobSubmission'
import fileExports from '@/functions/fileExports'
import { toast } from 'bulma-toast'

export default {


    setup(){
      let {copyFile} = fileExports();
      let {submitJob,createPostRequest} = jobSubmission();
      return {submitJob,createPostRequest,copyFile};
    },

    data() {
        return {
            cicdProvider: '',
            jobType:'',
            trigger:'',
            deploymentType:'',
            authentication:'',
            error:'',
            formSubmitted:false,
            consumerKeySecret:'',
            jwtSecretKey:'',
            salesforceUsernameSecret:'',
            salesforceLoginUrlSecret:'',
            sfdxUrlSecret:'',
            jobName:'',
            runPMD:false,
            fileName:'',
            branches:'',
            paths:'force-app/**',
            testsType:'all',
            pmdCategories:[],
            response:''

        };
    },

    methods:{

        copy(data){

          this.copyFile(null,data);
          toast({
            message: 'Copied!',
            type: 'is-success',
            dismissible: true,
            position:'top-center',
            duration:3000
          })
         
        },

        async submit(){
            
            let job = this.$data;

            job.safeJobName = this.jobName.replace(/\s/g,'-').toLowerCase();
    
            this.fileName = job.safeJobName;

            console.log('categories',this.pmdCategories)

            let res = await fetch('api/yaml',this.createPostRequest(job));

            if(res.status != 200){
              this.error = await res.text();
            }
            else{
              this.response = await res.text();
              this.formSubmitted = true;
            }     

            this.$nextTick(function(){
              window.scrollBy({
                top: 200,
                left: 0,
                behavior: 'smooth'
              });
            })

            
        }

    },

    computed: {

        showBranchesAndPaths() {
            return ['pull_request','push'].includes(this.trigger);
        },

        isFormValid(){

            return (this.cicdProvider && this.jobType
                && this.trigger && this.authentication && this.testsType)
        },

        selectedJobFriendlyName(){
          if(this.jobType == 'tests'){
            return 'run all tests'
          }
          else if(this.jobType == 'deployment'){
            return 'deploy to your org'
          }
          else if(this.jobType == 'validation'){
            return 'validate a deployment'
          }
        }

    }
}
</script>

<style lang="scss" scoped>

p{
  font-size: 16px;
}

li{
  margin-top:8px;
  margin-left: 18px;
}

form {
  padding: 40px;
  border-radius: 5px;
  border:thin solid black;
  background-color: white;

}

.uppercase{
  text-transform: uppercase;
}

.pmd{
    margin-left: 50px;
    margin-bottom: 30px;
}

.explanation {
    margin-bottom: 12px;
}

.field{
    margin-bottom: 25px;
}

.highlight{
  font-weight: bold;
}

.warning{
  color:#e74c3c;
  font-weight: bold;
}

.divider{
  border: 1px solid rgb(191, 190, 190);
  margin-bottom: 25px;
}

.section-title{
  background-color: #767e81;
  color:white;
  margin-bottom: 25px;
  font-size: 24px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 1px;
}



pre{
  padding: 20px;
  border-radius: 5px;
  border:thin solid black;
  margin-top:50px;
  background-color: #2c3e50;
}

.instructions{
  padding: 20px;
}

pre {
  code {
    color:#00c7ff;
  }
}

.results{
  margin-top: 50px;
}

.cta{
  background-color:$main-cta;
  color:white;
}

</style>>
