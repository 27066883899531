
import '@/assets/main.scss';
import '@/assets/bulma-tooltip.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


import toast from 'bulma-toast'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


const app = createApp(App)

router.app = app;
app.use(router);
app.use(toast);

app.mount('#app')
