<template>

    <section class="intro">
        <h1>Create a Salesforce CI/CD pipeline in minutes, not weeks</h1>
        <br>
        <p>
            A Salesforce CI/CD pipeline is a method to automate deployments to your
            Salesforce orgs based on actions in your remote git repository.
        </p>
        <br>
         <p>Most CI/CD providers (GitHub Actions, Bitbucket Pipelines, etc.) require you to create long and complicated YAML files that describe the actions you want to automate. This often
            requires additional scripting via Bash or Node.js, which increases the complexity of such configuration files.
         </p>
        <br />
        <p>This <b>free</b> app will let you create a pipeline configuration file with clicks, not code!</p>
        <br>
        <p>You can then simply copy/paste the resulting YAML file into your CI server and you are done!</p>
        <br><br>
        <div class="cta">
        <button class="button " @click="$router.push('/app')">
            <span>Get started</span>
        </button>
        </div>
        <br>
        <div class="second-cta">
            <p>Are you new to CI/CD?</p> 
            <p>My SalesforceBen article <a href="https://www.salesforceben.com/build-your-own-ci-cd-pipeline-in-salesforce-using-github-actions/" target="_blank" 
            >Build Your Own CI/CD Pipeline in Salesforce (Using GitHub Actions)</a> gives you everything you need to know to get started!</p>
        </div>
        
    </section> 
   
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

    p{
        font-size: 20px;
    }

    .intro{
        
        padding:20px
       
    }

    h1{
        font-size: 30px;
    }

    .cta{
        text-align: center;
        button{
            background-color: $main-cta;
            color:white;
            font-size: 20px;
        }
        
    }

    .second-cta{
        margin-top: 20px;

        p{
            font-size: 16px;
       
            text-align: center;
        }
        
    }

</style>

