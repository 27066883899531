import {ref} from 'vue';

function jobSubmission(){

    let intervalId;
    let apiError = ref(null);
    let apiResponse = ref(null);
    let done = ref(true);

    async function submitJob(url,fetchOptions = {}){

        let res = await fetch(url,fetchOptions);
        let text = await res.text();
    
        return text;
    }



    function createPostRequest(data){

        let fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        return fetchOptions;
    }

    return {submitJob,apiError,apiResponse,done,createPostRequest};

}

export default jobSubmission;