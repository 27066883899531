<template>
  <header>
    <h1>
      <a class="app-title" href="/">Not <span class="highlight">another</span> CI tool</a> (beta)</h1>
      
    <p>FREE Auto-generated CI/CD pipelines for SFDX's org development model—by <a
    href="https://www.linkedin.com/in/pablis/"
    target="_target" style="color:white;font-weight:bold"
    >Pablo Gonzalez</a></p>
  </header>
  <section class="main">
    <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</section>
</template>

<script>



export default {
  


  
}
</script>


<style lang="scss" >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8f9;
}

header{
  padding-left:30px;
  padding-top:20px;
  padding-bottom: 20px;
  background-color: $background-color;
  color:white;
  p{
    color:white;
  }
}

h1{
  font-size:25px;
    a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
  }
}


.app-title{
  color:white;
}

.main{
   max-width: 1000px;
   margin: auto;
   margin-top:50px;
   
}

p,li{
  color:#354062;
}
</style>
