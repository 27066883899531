import { createRouter, createWebHistory } from 'vue-router'
import YamlGenerator from '../views/yaml-generator/YamlGenerator.vue';
import NotFound from '../views/not-found/NotFound.vue';
import Landing from '../views/landing/Landing.vue';


const routes = [

  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/app',
    name: 'YamlGenerator',
    component: YamlGenerator,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})





export default router
